<template>
    <main>
        <b-overlay :show="loading">
            <validation-observer ref="form">
                <b-card>
                    <b-form-group v-if="!isInvoiceAvailable">
                        <label for="">Pilih No.Invoice</label>
                        <v-select v-model="selectedInvoice" :reduce="option => option.value" :options="invoiceList" label="text" />
                    </b-form-group>
                    <p class="mb-0" v-else>
                        <strong>ID {{pb.id}} No. Invoice: {{pb.id}}</strong>
                    </p>
                </b-card>
                <!-- two card -->
                <b-row>
                    <!-- List barang di penerimaan -->
                    <b-col sm="12" md="6">
                        <b-card>
                            <b-table responsive striped bordered :fields="fields" :items="barangs">
                                <template #cell(action)="{item}">
                                    <b-form-radio v-model="selectedBarang" :value="item" :name="`barang`" />
                                </template>
                                <template #cell(barang)="{item}">
                                    {{ item.barang ? item.nama : '-' }}
                                </template>
                                <template #cell(satuan)="{item}">
                                    {{ item.barang && item.satuan ? item.satuan.satuan : '-' }}
                                </template>
                            </b-table>
                        </b-card>
                    </b-col>
                    <!-- Penempatan gudang -->
                    <b-col sm="12" md="6">
                        <b-card>
                            <form>
                                <b-form-group v-if="!isInvoiceAvailable" hidden>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="penerimaan"
                                        rules="required"
                                    >
                                        <label for="">Pilih Penerimaan</label>
                                        <v-select 
                                            :options="invoiceList"
                                            v-model="form.penerimaan_id"
                                            :required="!form.penerimaan_id"
                                            :reduce="option => option.value"
                                            label="text" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="gudang"
                                        rules="required"
                                    >
                                        <label for="">Pilih Gudang</label>
                                        <v-select 
                                            :options="gudangs"
                                            v-model="form.gudang_id"
                                            :required="!form.gudang_id"
                                            :reduce="option => option.value"
                                            label="text" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="blok"
                                        rules="required"
                                    >
                                        <label for="">Pilih Blok</label>
                                        <v-select 
                                            :options="bloks"
                                            v-model="form.blok_id"
                                            :required="!form.blok_id"
                                            :reduce="option => option.value"
                                            label="text" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group>
                                        <label for="">Pilih Pallet</label>
                                        <v-select 
                                            :options="palets"
                                            v-model="form.palet_id"
                                            :reduce="option => option.value"
                                            label="text" />
                                </b-form-group>
                                <b-form-group>
                                    <label for="">Pilih Rak (optional)</label>
                                    <v-select 
                                        v-model="form.rak_id"
                                        :options="raks"
                                        :reduce="option => option.value"
                                        label="text" class="mb-2" />
                                </b-form-group>
                                <b-form-group>
                                    <label for="">Pilih Laci (optional)</label>
                                    <v-select 
                                        v-model="form.laci_id"
                                        :options="lacis"
                                        :reduce="option => option.value"
                                        label="text" class="mb-2" />
                                </b-form-group>
                                <b-form-group >
                                    <validation-provider 
                                        #default="{errors}"
                                        name="jumlah"
                                        rules="required|positive"
                                    >
                                        <label for="">Jumlah</label>
                                        <b-form-input type="number" v-model="form.jumlah" class="mb-2" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
        
                                <b-form-group>
                                    <b-button @click.prevent="addBarang" variant="primary">Tambah</b-button>
                                </b-form-group>
                            </form>
                        </b-card>
                    </b-col>
                    <!-- Barang yang sudah disimpan -->
                    <b-col cols="12">
                        <b-card>
                            <b-table :fields="fieldsAddedItems" bordered striped responsive :items="addedItems" >
                                <template #cell(no)="row">
                                    {{(row.index + 1)}}
                                </template>
                                <template #cell(lokasi)="{item}">
                                    <strong>
                                        {{ item.lokasi }}
                                    </strong>
                                </template>
                                <template #cell(action)="row">
                                    <b-link class="text-danger" @click.prevent="addedItems.splice(row.index, 1)">
                                        <feather-icon size="24" icon="XCircleIcon"></feather-icon>
                                    </b-link>
                                </template>
                            </b-table>
                            <div class="d-flex justify-content-end">
                                <b-button variant="primary" @click.prevent="submit">
                                    Simpan
                                </b-button>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-overlay>
    </main>
</template>
<script>
    import {BTable, BOverlay, BFormRadio, BLink, BFormGroup, BRow, BCol, BCard, BFormCheckbox, BButton, BFormInput} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { extend } from 'vee-validate';
    import { ValidationProvider, ValidationObserver } from "vee-validate"
    import { required } from 'vee-validate/dist/rules'
    extend('required', {
        ...required,
        message: 'This field is required'
    })
    extend('positive', value => {
        return value > 0
    })

    export default {
        components: {
            vSelect,
            BLink,
            ValidationObserver, ValidationProvider,
            BOverlay, BFormGroup, BFormRadio,
            BTable, BRow, BCol, BCard, BFormCheckbox,BButton, BFormInput
        },
        watch: {
            "form.gudang_id"(gudang_id) {
                this.getBlok(gudang_id)  
            },
            "form.blok_id"(blok_id) {
                this.getpalet(blok_id)
            },
            "form.palet_id"(palet_id) {
                this.getRak(palet_id)
            },
            "form.rak_id"(rak_id) {
                this.getLaci(rak_id)
            },
            selectedInvoice(penerimaan_id) {
                const penerimaan = this.$store.getters['mutasi/getData'](penerimaan_id)
                if(!penerimaan) {
                    this.displayError({
                        message: 'Data Penerimaan tidak ditemukan, harap pilih yang lain'
                    }) 
                    return false
                }

                this.pb = penerimaan
                this.form.penerimaan_id = this.pb.id
                this.params = {
                    penerimaan_id
                }
                this.barangs = this.pb.barang
                this.getBarangPenerimaan()
            }
        },
        data: () => ({
            params: {},
            loading: false,
            selectedInvoice: null,
            selectedBarang: null,
            addedItems: [],
            fieldsAddedItems: [
                {key: 'no', label: 'No'},
                {key: 'barang', label: 'Barang'},
                {key: 'jumlah', label: 'Jumlah'},
                {key: 'lokasi', label: 'Lokasi'},
                {key: 'action', label: '#'}
            ],
            fields: [
                {key: 'action', label: '#'},
                {key: 'nama', label: 'Barang'}, 
                {key: 'sisa', label: 'Sisa'},
                {key: 'satuan', label: 'Satuan'}
            ],
            bloks: [],
            pb: {},
            raks: [],
            lacis: [],
            palets: [],
            gudangs: [],
            barangs: [],
            form: {
                penerimaan_id: null,
                gudang_id: null,
                blok_id: null,
                palet_id: null,
                rak_id: null,
                laci_id: null
            },
            invoiceList: []
        }),
        methods: {
            submit() {
                this.addedItems.map(item => {
                    delete item.barang
                    delete item.lokasi
                })
                let payload = []
                Object.assign(payload, this.addedItems)

                this.loading = true
                this.$store.dispatch('penyimpanan/save', payload)
                .then(() => {
                    this.loading = false
                    this.displaySuccess({
                        message: 'Stok Barang berhasil ditambahkan!'
                    })

                    setTimeout(() => {
                        this.$router.push('/request-mutasi')
                    }, 1000)
                })
                .catch(e => {
                    this.loading = false
                    this.displayError(e)
                    return false
                })
            },
            addBarang() {
                if(this.form.jumlah > this.pb.jumlah ) {
                    this.$bvToast.toast('Jumlah yang diisi tidak boleh lebih dari Sisa jumlah barang', {
                        title: `Peringatan`,
                        variant: 'danger',
                        solid: true,
                        toaster: 'b-toaster-bottom-right',
                    })
                    // this.displayError({
                    //     message: 'Jumlah yang disimpan harus kurang dari atau sama dengan sisa stok penerimaan'
                    // })
                    return false
                }
                this.$refs.form.validate().then(success => {
                    if(success) {
                        let data = {}
                        Object.assign(data, this.form)
                        data.barang = this.pb && this.pb.barang ? this.pb.barang.nama : null
                        data.barang_id = this.pb && this.pb.barang ? this.pb.barang.id : null

                        // get lokasi
                        const blok = this.bloks.find(blok => blok.value == data.blok_id)
                        const palet = data.palet_id ? this.palets.find(palet => palet.value == data.palet_id) : null
                        const rak = data.rak_id ? this.raks.find(rak => rak.value == data.rak_id) : null
                        const laci = data.laci_id ? this.lacis.find(laci => laci.value == data.laci_id) : null

                        let lokasi = blok ? `Blok: ${blok.text}` : ''
                        if(palet) {
                            lokasi += `, Palet: ${palet.text}`
                        }
                        if(rak) {
                            lokasi += `, Rak: ${rak.text}`
                        }

                        if(laci) {
                            lokasi += `, Laci: ${laci.text}`
                        }
                        data.lokasi = lokasi
                        this.addedItems.push(data)
                        this.form.blok_id= null,
                        this.form.palet_id= null,
                        this.form.rak_id= null,
                        this.form.laci_id= null
                    }
                })
            },
            getGudang() {
                this.gudangs = []
                this.$store.dispatch('gudang/getData', {jenis: 1})
                .then(gudangs => {
                    gudangs.forEach(gudang => {
                        
                        if(this.isAdminGudang) {
                            const itsMyGudang = gudang.id == this.myGudang.id
                            if(itsMyGudang) {
                                this.gudangs.push({
                                    value: gudang.id,
                                    text: gudang.nama_gudang
                                })
                            }
                        }
                        else {
                            this.gudangs.push({
                                value: gudang.id,
                                text: gudang.nama_gudang
                            })
                        }
                    })
                })
            },
            getBlok(gudang_id){
                this.bloks = []
                this.$store.dispatch('blok/getData', {gudang_id})
                .then(bloks => {
                    bloks.forEach(blok => {
                        this.bloks.push({
                            value: blok.id,
                            text: blok.blok
                        })
                    })
                })
            },
            getpalet(blok_id){
                this.palets = []
                this.$store.dispatch('pallet/getData', {blok_id})
                .then(palets => {
                    palets.forEach(palet => {
                        this.palets.push({
                            value: palet.id,
                            text: palet.palet
                        })
                    })
                })
            },
            getRak(palet_id){
                this.raks = []
                this.$store.dispatch('rak/getData', {palet_id})
                .then(raks => {
                    raks.forEach(rak => {
                        this.raks.push({
                            value: rak.id,
                            text: rak.rak
                        })
                    })
                })
            },
            getLaci(rak_id){
                this.lacis = []
                this.$store.dispatch('laci/getData', {rak_id})
                .then(lacis => {
                    lacis.forEach(laci => {
                        this.lacis.push({
                            value: laci.id,
                            text: laci.laci
                        })
                    })
                })
            },
            async getBarangPenerimaan() {
                await this.$store.dispatch('mutasi/getDataById', this.pb.id)
                .then(barangs => {
                    this.barangs = barangs
                })
                .catch(e => this.displayError(e))
            },
            async getInvoicePenerimaan() {
                // let params = {}
                // if(this.isAdminGudang) {
                //     params.tujuan = this.myGudang.id
                // }
                this.loading = true
                const mutasi = await this.$store.dispatch('mutasi/getDataById', this.$route.params.id)
                .then(() => {
                    this.loading = false
                    this.pb = mutasi


                    if(this.$route.params.id) {
                        this.selectedInvoice = this.$route.params.id
                    }

                    this.getGudang()
                    // this.getBarangPenerimaan()
                })
                .catch(e => {
                    this.loading = false
                    this.displayError(e)
                    return false
                })
            }
        },
        computed: {
            isInvoiceAvailable() {
                return this.$route.params.id
            }
        },
        created() {
            this.getInvoicePenerimaan()    
        },
    }
</script>